/* Define custom properties */
:root {
    --primary-color: #26271a;
    --secondary-color: #6a425c;
    --accent-color: #ffa9a9;
    --background-color: #fffbe3;
}

/* Table Colors */
.ciklama-table {
    background-color: var(--secondary-color);
    color: white; /* Adjust text color if needed */
}

.ciklama-table thead th {
    background-color: var(--secondary-color); /* Header background color */
    color: white; /* Header text color */
}

.ciklama-table tbody tr td {
    background-color: var(--accent-color); /* Row background color */
    color: var(--secondary-color); /* Row text color */
}

.ciklama-table tr:hover {
    background-color: var(--background-color); /* Hover color */
}

/* Table Columns width */
.col-select {
    width: 10%;
}

.col-order-id {
    width: 15%;
}

.col-customer-name {
    width: 20%;
}

.col-product-type {
    width: 20%;
}

.col-price {
    width: 10%;
}

.col-description {
    width: 15%;
}

.col-status {
    width: 10%;
}

/* Button styling */
.login-btn {
    margin-top: 5px;
}

.login-btn-container {
    display: flex; 
    justify-content: center; 
    align-items: center;
}

.login-form-container {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
}

/* Button styles */

.save-btn {
    background-color: var(--secondary-color);
    color: var(--background-color);
    margin-right: 10px;
    margin-top: 5px;
    border: 2px solid var(--accent-color);
}

.cancel-btn {
    background-color: var(--primary-color);
    color: var(--background-color);
    margin-top: 5px;
    border: none;
}

.save-btn:hover, .cancel-btn:hover {
    background-color: var(--accent-color);
    border: 2px solid var(--accent-color);
}


.form-container {
    background-color: var(--secondary-color); /* Set background color */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    min-height: 100vh; /* Full height */
    padding: 20px; /* Add some padding */
    color: var(--background-color);
}

/* order list style */

.order-container  {
    /*background-color: var(--secondary-color);*/
}
